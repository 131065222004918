/* Styles Swiper navigation using icon-arrow-circle-left.svg and icon-arrow-circle-right.svg */

.foraSwiper swiper-container::part(button-prev),
.foraSwiper swiper-container::part(button-next) {
  --swiper-navigation-size: 24px;
  --swiper-navigation-top-offset: calc(50% - 12px);
  --swiper-navigation-sides-offset: 16px;
  @apply hidden w-6 h-6 lg:block;
  @apply bg-center bg-no-repeat bg-contain;
}

.foraSwiper swiper-container::part(button-prev) {
  background-image: url('https://media.foratravel.com/image/upload/v1727798170/icon-arrow-circle-left_xkqdcq.svg');
}

.foraSwiper swiper-container::part(button-prev) .swiper-button-disabled {
  opacity: 0.5;
}

.foraSwiper swiper-container::part(button-next) {
  background-image: url('https://media.foratravel.com/image/upload/v1727798451/icon-arrow-circle-right_d4ezbp.svg');
}

.foraSwiper swiper-container::part(button-next) .swiper-button-disabled {
  opacity: 0.5;
}
